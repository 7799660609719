import { RouteRecordRaw } from "vue-router";

export enum InvitationRouteName {
  INVITATION = "INVITATION",
}

export const invitationRoutes: RouteRecordRaw[] = [
  {
    path: "/invitation/:token",
    name: InvitationRouteName.INVITATION,
    component: (): Promise<any> =>
      import("@/views/Invitation/InvitationView.vue"),
    props: true,
    meta: { requiredAuth: true, announcer: { skip: true } },
  },
];
